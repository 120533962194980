import * as pug from './pugFunctions'
export function createGallery(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (event, image) {
      pug_html = pug_html + "\u003Cdiv class=\"card splide__slide\"\u003E\u003Cdiv class=\"card-body mx-0 px-0\"\u003E\u003Cimg" + (" class=\"card-image\""+pug.attr("src", `${image}`, true, false)+pug.attr("alt", `${event}`, true, false)) + "\u002F\u003E\u003Cbutton class=\"btn btn-primary w-100 open-photos\" data-bs-toggle=\"modal\" data-bs-target=\"#galleryModal\"\u003E \u003Cspan\u003E" + (pug.escape(null == (pug_interp = event) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cbr class=\"het\"\u002F\u003E\u003Ci class=\"bi bi-images pe-3\"\u003E\u003C\u002Fi\u003EView Images\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "event" in locals_for_with ?
        locals_for_with.event :
        typeof event !== 'undefined' ? event : undefined, "image" in locals_for_with ?
        locals_for_with.image :
        typeof image !== 'undefined' ? image : undefined));
    ;;return pug_html;}