import * as bootstrap from "bootstrap";

import { getEvents, getGallery, getResources } from "./prismic";
import Splide from "@splidejs/splide";

document.getElementById("navbar-link").addEventListener("click", closeNav);
document
  .getElementById("navbar-link-small")
  .addEventListener("click", closeNav);
document.getElementById("home-link").addEventListener("click", closeNav);
document.getElementById("statement-link").addEventListener("click", closeNav);

document.getElementById("resources-link").addEventListener("click", closeNav);


document.getElementById("contact-link").addEventListener("click", closeNav);
document.getElementById("event-link").addEventListener("click", closeNav);
document.getElementById("gallery-link").addEventListener("click", closeNav);

function closeNav() {
  var myCollapse = document.getElementById("navbarTogglerDemo02");
  var bsCollapse = new bootstrap.Collapse(myCollapse, {
    toggle: false,
  });
  bsCollapse.hide();
}

/**
 * Email validation, mailing form request
 */

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

var form = document.getElementById("mailingForm");
var msg = document.getElementById("form-message");
var input = document.getElementById("mailinput");

function successMailing() {
  document.getElementById("mailClose").click();
  document.getElementById("topAlert").style.display = "block";
}

function successContact() {
  var myModal = new bootstrap.Modal(document.getElementById("submitModal"), {});
  myModal.show();
  cform.reset();
}

form.onsubmit = function (event) {
  event.preventDefault();

  var mail = document.getElementById("mailinput").value;

  if (validateEmail(mail)) {
    let myForm = document.getElementById("mailingForm");
    let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => successMailing())
      .catch((error) => alert(error));
  } else {
    // Email was not validated correctly
    msg.innerHTML = "Please enter a valid email address.";
  }
};

// Remove error if input is changed
input.addEventListener("change", (event) => {
  msg.innerHTML = "";
});

/**
 * Contact form
 */

var cform = document.getElementById("contactForm");

var m = document.getElementById("email");
var fname = document.getElementById("first-name");
var lname = document.getElementById("last-name");
var message = document.getElementById("message");

cform.onsubmit = function (event) {
  event.preventDefault();

  if (!validateEmail(m.value)) {
    document.getElementById("emailErr").style.display = "block";
    document.getElementById("emailErr").innerHTML =
      "Please enter a valid email.";
  }

  if (fname.value == "") {
    document.getElementById("fnameErr").style.display = "block";
    document.getElementById("fnameErr").innerHTML =
      "First name cannot be empty.";
  }
  if (lname.value == "") {
    document.getElementById("lnameErr").style.display = "block";
    document.getElementById("lnameErr").innerHTML =
      "Last name cannot be empty.";
  }

  if (message.value == "") {
    document.getElementById("messageErr").style.display = "block";
    document.getElementById("messageErr").innerHTML =
      "Message cannot be empty.";
  }

  if (
    validateEmail(m.value) &&
    fname.value != "" &&
    lname.value != "" &&
    message.value != ""
  ) {
    let myForm = document.getElementById("contactForm");
    let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => successContact())
      .catch((error) => alert(error));
  }
};

// Cleansing errors after changed input.
m.addEventListener("change", (event) => {
  document.getElementById("emailErr").style.display = "none";
  document.getElementById("emailErr").innerHTML = "";
});
fname.addEventListener("change", (event) => {
  document.getElementById("fnameErr").style.display = "none";
  document.getElementById("fnameErr").innerHTML = "";
});
lname.addEventListener("change", (event) => {
  document.getElementById("lnameErr").style.display = "none";
  document.getElementById("lnameErr").innerHTML = "";
});
message.addEventListener("change", (event) => {
  document.getElementById("messageErr").style.display = "none";
  document.getElementById("messageErr").innerHTML = "";
});

var options = {
  perPage: 3,
  pagination: false,
  arrowPath: "M18 35L34.5 18.5L18 2",
  breakpoints: {
    1080: {
      perPage: 2,
    },
    740: {
      perPage: 1,
    },
  },
  keyboard: "focused",
};

function home() {
  location.hash = "#home";
  removeAll();
  document.getElementById("home-section").style.display = "flex";
  //document.getElementById("home-link").style.display = 'none'
  document.getElementById("home-link").firstChild.classList.add("active");
  document.getElementById("bg-image").style.display = "flex";
  document.getElementById("vesulo").style.display = "block";

  var el = document.getElementById("main-section");
  el.classList.remove("col-lg-12");
  el.classList.add("col-lg-8");
  el.classList.add("full-bg");
}

function statement() {
  location.hash = "#statement";
  removeAll();
  document.getElementById("statement-section").style.display = "flex";
  document.getElementById("statement-link").firstChild.classList.add("active");
  nonMain();
}

function contact() {
  location.hash = "#contact";
  removeAll();
  document.getElementById("contact-section").style.display = "flex";
  document.getElementById("contact-link").firstChild.classList.add("active");
  nonMain();
}

function events() {
  location.hash = "#events";
  removeAll();
  document.getElementById("events-section").style.display = "flex";
  document.getElementById("event-link").firstChild.classList.add("active");
  nonMain();
  var splide = new Splide("#splide__events", options).mount();
  var addButton = document
    .getElementById("splide__events")
    .querySelector(".splide__arrow--next");
  addButton.addEventListener("click", function () {
    getEvents(splide);
  });
  getEvents(splide);
}

function resources() {
  location.hash = "#resources";
  removeAll();
  document.getElementById("resources-section").style.display = "flex";
  document.getElementById("resources-link").firstChild.classList.add("active");
  nonMain();
  var splide = new Splide("#splide__resources", options).mount();
  getResources(splide);
}

function gallery() {
  location.hash = "#gallery";
  removeAll();
  document.getElementById("gallery-section").style.display = "flex";
  document.getElementById("gallery-link").firstChild.classList.add("active");
  nonMain();
  var splide = new Splide("#splide__gallery", options).mount();
  var addButton = document
    .getElementById("splide__gallery")
    .querySelector(".splide__arrow--next");

  addButton.addEventListener("click", function () {
    getGallery(splide);
  });
  getGallery(splide);
}

function nonMain() {
  document.getElementById("home-link").style.display = "block";
  document.getElementById("bg-image").style.display = "none";
  var el = document.getElementById("main-section");
  el.classList.add("col-lg-12");
  el.classList.remove("col-lg-8");
  el.classList.remove("full-bg");
  document.getElementById("vesulo").style.display = "none";
}

function removeAll() {
  for (const li of document.querySelectorAll("li.nav-item")) {
    li.firstChild.classList.remove("active");
  }

  for (const div of document.querySelectorAll("div.info")) {
    div.style.display = "none";
  }
}

function locationHashChanged() {
  var hash = location.hash.substr(1);
  switch (hash) {
    case "statement":
      statement();
      break;
    case "contact":
      contact();
      break;
    case "events":
      events();
      break;
    case "gallery":
      gallery();
      break;
    case "resources":
      resources();
      break;
    default:
      home();
      break;
  }
}

locationHashChanged();
window.onhashchange = locationHashChanged;
