import * as pug from './pugFunctions'
export function createResource(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (body, name, tagline) {
      pug_html = pug_html + "\u003Cdiv class=\"card splide__slide\"\u003E\u003Cdiv class=\"card-body mx-3\"\u003E\u003Ch5 class=\"card-title\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fh5\u003E\u003Ch6 class=\"card-subtitle\"\u003E" + (pug.escape(null == (pug_interp = tagline) ? "" : pug_interp)) + "\u003C\u002Fh6\u003E\u003Cp class=\"card-text\"\u003E" + (pug.escape(null == (pug_interp = body) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003Cbutton class=\"btn btn-primary w-100\" data-bs-toggle=\"modal\" data-bs-target=\"#resourceModal\"\u003EView Details\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "body" in locals_for_with ?
        locals_for_with.body :
        typeof body !== 'undefined' ? body : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined, "tagline" in locals_for_with ?
        locals_for_with.tagline :
        typeof tagline !== 'undefined' ? tagline : undefined));
    ;;return pug_html;}