import Prismic from "@prismicio/client";
import PrismicDom from "prismic-dom";
import { DateTime } from "luxon";
import { createEvent } from "./templates/event";
import { createGallery } from "./templates/gallery";
import { createResource } from "./templates/resource";
require('fslightbox');
//import refreshFsLightbox from './fslightbox';

var apiEndpoint = "https://chattio.cdn.prismic.io/api/v2";

var api = Prismic.client(apiEndpoint);

var pg = 1;

var gpg = 1;
var q = 1;
export function getResources(splide) {
  api
    .query(Prismic.Predicates.at("document.type", "resource"), {
      orderings: "[document.first_publication_date desc]",
      pageSize: 10,
      page: q,
    })
    .then(
      function (response) {
        if (response.results.length < 1){
          return
        }
        response.results.forEach((event) => {
          var name = PrismicDom.RichText.asText(event.data.event_name);
      
          var body = PrismicDom.RichText.asText(event.data.event_detail);
          var tagline = PrismicDom.RichText.asText(event.data.resource_type);
          var images = event.data.photos;
          var ev = createResource({ name, tagline, body });
          ev = htmlToElement(ev);
          ev.firstChild.lastChild.onclick = function () {
            resourceDets(
              name,
              tagline,
              PrismicDom.RichText.asHtml(event.data.event_detail),
              images
            );
          };
          splide.add(ev);
        });
        q++;
        getResources(splide)
      },
      function (err) {
        console.log("Something went wrong: ", err);
      }
    );
}

export function getEvents(splide) {
  let menu = document.getElementById("events");
  let children = menu.children;
  if (children.length > 4) {
    if (
      children[children.length - 1].tabIndex == -1 &&
      children[children.length - 2].tabIndex == -1 &&
      children[children.length - 3].tabIndex == -1 &&
      children[children.length - 4].tabIndex == -1
    ) {
      return;
    }
  }



  api
    .query(Prismic.Predicates.at("document.type", "eve"), {
      orderings: "[my.eve.event_date desc]",
      pageSize: 7,
      page: pg,
    })
    .then(
      function (response) {
        response.results.forEach((event) => {
          var name = PrismicDom.RichText.asText(event.data.event_name);
          var dt = DateTime.fromJSDate(PrismicDom.Date(event.data.event_date));
          var date = dt.toLocaleString(DateTime.DATE_FULL);
          var body = PrismicDom.RichText.asText(event.data.event_detail);
          var ev = createEvent({ name, date, body });
          ev = htmlToElement(ev);
          ev.firstChild.lastChild.onclick = function () {
            eventDets(
              name,
              date,
              PrismicDom.RichText.asHtml(event.data.event_detail)
            );
          };
          splide.add(ev);
        });
        pg++;
      },
      function (err) {
        console.log("Something went wrong: ", err);
      }
    );
}

export function getGallery(splide) {
  let menu = document.getElementById("gallery");
  let children = menu.children;

  // If there's more than 4 gallery cards
  if (children.length > 4) {
    if (
      children[children.length - 1].tabIndex == -1 &&
      children[children.length - 2].tabIndex == -1 &&
      children[children.length - 3].tabIndex == -1 &&
      children[children.length - 4].tabIndex == -1
    ) {
      return;
    }
  }else if (children.length > 0) {
    return
  }

  api
    .query(Prismic.Predicates.at("document.type", "photo_gallery"), {
      orderings: "[my.photo_gallery.event_date desc]",
      pageSize: 7,
      page: gpg,
    })
    .then(
      function (response) {
        response.results.forEach((event) => {
          var name = PrismicDom.RichText.asText(event.data.event_title);
          var dt = DateTime.fromJSDate(PrismicDom.Date(event.data.event_date));
          var date = dt.toLocaleString(DateTime.DATE_FULL);
          var images = event.data.images;
          if (images.length > 0 ) {
          var ev = createGallery({ event: name, image: images[0].photo.url });
          ev = htmlToElement(ev);
          ev.firstChild.lastChild.onclick = function () {
            eventImages(
              name,
              date,
              images
            );
          };
          splide.add(ev);
        }
        });
        gpg++;
      },
      function (err) {
        console.log("Something went wrong: ", err);
      }
    );
}

function eventDets(title, date, body) {
  document.getElementById("ed-t").innerText = title;
  document.getElementById("ed-d").innerText = date;
  document.getElementById("ed-b").innerHTML = body;
}
function resourceDets(title, date, body, images) {
  document.getElementById("rd-t").innerText = title;
  document.getElementById("rd-d").innerText = date;
  document.getElementById("rd-b").innerHTML = body;
  document.getElementById("rd-g").innerHTML = "";
  var d = document.createElement("DIV");
  d.classList.add("column-grid")
  var x = 0;
  var z = images.length / 2
  z = ~~z
  if (z == 0) {
    z = 1
  }
  images.forEach(function (image) {
    var a = document.createElement("a");
    a.setAttribute("data-fslightbox", "resources");
    a.setAttribute("href", image.images.url)
    var e = document.createElement("IMG");
    e.setAttribute("src", image.images.url);
    e.setAttribute("style", "width:100%;"); // Append a tag with image

    a.appendChild(e); // Append div with a tag

    d.appendChild(a);
    x++;

    if (x == z) {
      x = 0;
      document.getElementById("rd-g").appendChild(d);
      d = document.createElement("DIV");
      d.classList.add("column-grid");
    }
  });
  document.getElementById("rd-g").appendChild(d)
  refreshFsLightbox();
}

function eventImages(title, date, images) {
  document.getElementById("eg-t").innerText = title;
  document.getElementById("eg-d").innerText = date;
  document.getElementById("eg-b").innerHTML = "";
  var d = document.createElement("DIV");
  d.classList.add("column-grid")
  var x = 0;
  var z = images.length / 2
  z = ~~z
  if (z == 0) {
    z = 1
  }
  images.forEach(function (image) {
    var a = document.createElement("a");
    a.setAttribute("data-fslightbox", "gallery");
    a.setAttribute("href", image.photo.url)
    var e = document.createElement("IMG");
    e.setAttribute("src", image.photo.url);
    e.setAttribute("style", "width:100%;"); // Append a tag with image

    a.appendChild(e); // Append div with a tag

    d.appendChild(a);
    x++;

    if (x == z) {
      x = 0;
      document.getElementById("eg-b").appendChild(d);
      d = document.createElement("DIV");
      d.classList.add("column-grid");
    }
  });
  document.getElementById("eg-b").appendChild(d)
  refreshFsLightbox();
}

function htmlToElement(html) {
  var template = document.createElement("template");
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}
